import React from 'react'
import { graphql } from 'gatsby'

import Header from '../components/Header'
import Footer from '../components/Footer'
import Leagues from '../components/Leagues'
import Lms from '../components/Lms'
import HeadTags from '../components/Headtags2'
// import GoogleAd from '../components/GoogleAd'
import SnackAd2 from '../components/SnackAd2'
import SnackAd from '../components/SnackAd'
// import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

// import Amplify from 'aws-amplify';
// import config from '../aws-exports';

// Amplify.configure(config);

const LeaguesPage = ({data}) => {
   const post = data.markdownRemark

   function LeagueType(){
     if(post.frontmatter.type==="league"){
       return (<Leagues lgCode = {post.frontmatter.code} lgName = {post.frontmatter.title}/>)
     }
     else {
       return (<Lms lgCode = {post.frontmatter.code} lgName = {post.frontmatter.title}/>)
     }}

    return (
    <>
      <HeadTags />
      <div style ={{position:"relative", minHeight:"100vh"}}>
      <Row>
          <SnackAd2 ad_type = "leader" ad_num = {1}/>
      </Row>
        <Header />
        <LeagueType />
        <Row>
          <SnackAd ad_type = "leader" ad_num = {4}/>
        </Row>
        <Footer />
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug} }) {
      html
      frontmatter {
        title
        keywords
        code
        type
      }
    }
  }
`

export default LeaguesPage
