import React from "react"
import AdSense from 'react-adsense'

// const GoogleAd = () => (
//   <div style ={{minWidth:"90%", minHeight:"150px"}}>
//   <ins className="adsbygoogle"
//        style={{display:"block"}}
//        data-ad-client="ca-pub-5673060940269252"
//        data-ad-slot="2357305147"
//        data-ad-format="auto"
//        data-full-width-responsive="true"></ins>
//   </div>
// )

class GoogleAd extends React.Component {

  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
// display:inline-block;width:728px;height:90px
render () {
  // const style_fn = this.props.ad_type === "horizontal" ? {display:"inline-block",width:"728px",height:"90px"} :{display:"block"};
  // const style_fn2 = {display:"inline-block",width:"728px",height:"90px"}
    return (
      <div style = {{margin: "auto"}}>
        <ins className="adsbygoogle"
           style={{display:"block"}}
           data-ad-client="ca-pub-5673060940269252"
           data-ad-slot={this.props.ad_type === "horizontal" ? "7855293384" : "2357305147"}
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
        </div>
    );
  }
}

// <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
// <!-- fpl_horizontal -->
// <ins class="adsbygoogle"
//      style="display:block"
//      data-ad-client="ca-pub-5673060940269252"
//      data-ad-slot="7855293384"
//      data-ad-format="auto"
//      data-full-width-responsive="true"></ins>
// <script>
//      (adsbygoogle = window.adsbygoogle || []).push({});
// </script>

// <ins class="adsbygoogle"
//      style="display:block"
//      data-ad-client="ca-pub-5673060940269252"
//      data-ad-slot="2357305147"
//      data-ad-format="auto"
//      data-full-width-responsive="true"></ins>

     // <ins className="adsbygoogle"
     // style="display:inline-block"
     // data-ad-client="ca-pub-5673060940269252"
     // data-ad-slot="2357305147"
     // data-ad-format="auto"></ins>

// const GoogleAd = () => (
//   <AdSense.Google
//       client='ca-pub-5673060940269252'
//       slot='2357305147'
//       style={{ display: 'block' }}
//       format='auto'
//       responsive='true'
//       layoutKey='-gw-1+2a-9x+5c'
//     />
// )
  // style= {{display:"block"}}

export default GoogleAd
