import React from "react";

import {Table,Container,Row,Col,Button} from "react-bootstrap"
import Modal from 'react-bootstrap/Modal'
import styles from './TeamPop.module.scss';
// import PropTypes from 'prop-types';
//
// <tr className = "table-success font-weight-bold">
//   <td style={{textAlign:"left"}}>Total</td>
//   <td></td>
//   <td style={{textAlign:"center"}}>{manager_h_info.tot_pts}</td>
// </tr>
// <tr className = "table-success font-weight-bold">
//   <td style={{textAlign:"left"}}>Total (inc transfers)</td>
//   <td></td>
//   <td style={{textAlign:"center"}}>{h_tot_pts_adj}</td>
// </tr>

const TeamPop = (props) => {
console.log("trigger");
  const show = props.show;
  const handleClose = props.handleClose;
  const manInfo = props.manInfo;
  const manTeam = props.manTeam;
  const manTots = props.manTots;
  const chip = manInfo.chip === "wildcard" ? "Wildcard" : manInfo.chip === "freehit" ? "Free Hit" : manInfo.chip === "3xc" ? "Triple Captain" : manInfo.chip === "bboost" ? "Bench Boost" : null;
  const man_tot= manInfo.tot_pts*1-manInfo.trans_cost*1;
  const live_pred = manTots.tot_live_proj_sub;
  const live_pred2 = manTots.tot_live_proj_sub-manInfo.trans_cost*1;
  // const chip_hdr = chip ===null ? null : "<div class='alert alert-success text-center'><strong>"+{chip}+" Played</strong></div>";

  function ChipHdr(){
    if(chip===null){
      return null;
    } else {
      return (
        <div className='alert alert-success text-center'
        style={{paddingTop:"2px",paddingBottom:"2px"}}>
        <strong>{chip+" Played"}</strong>
        </div>
      )
    }
  }


  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className = {styles.TeamPopTitle}>
          {manInfo.manager}<br/><span className = {styles.TeamPopTitle2}>{manInfo.team_name}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{paddingBottom:"0px"}}>
        <ChipHdr/>
        <Table
          size="sm"
          className = {styles.TeamPopTab  + " shadow " + styles.thin_td}
        >
          <thead className="table-dark">
            <tr>
              <th style={{textAlign:"left"}}>Player</th>
              <th>Score</th>
              <th>Pts</th>
            </tr>
          </thead>
          <tbody>
            {manTeam.map(plyr => {
              const live_pred_plyr = Math.round(plyr.live_pred)*Math.max(plyr.wgt,1)
              const h_pts = plyr.pord <12 ? plyr.live_pts : plyr.wk_pts;
              const h_pts2 = plyr.start==1 || plyr.fin==1 ? h_pts : plyr.no_games==2 && plyr.games_played==2 ? h_pts : "("+live_pred_plyr+")";
              const h_pts_format = plyr.start==1 || plyr.fin==1 ? "" : plyr.no_games==2 && plyr.games_played==2 ? "" : " font-italic text-secondary";
              const h_pts_format2 = plyr.start==1 && plyr.fin_prov==0 ? " font-weight-bold" : "";
              const h_dgw = plyr.no_games==2 && plyr.games_played==1 && plyr.fin_prov==1 ? "("+live_pred_plyr+")" : plyr.no_games==2 && plyr.games_played==2 && plyr.start==0 ? "("+live_pred_plyr+")" :"";
              const rowColour = plyr.pord <12 ? "table-default" : "table-secondary";
              const h_sub_emoji = plyr.auto_sub_out==1 ? "\ud83d\udd03" : plyr.auto_sub_in==1 ? "\ud83d\udd03" : plyr.cap_switch==1 ? "\u002A\uFE0F\u20E3":"";
              // const h_mins = plyr.start==1 || plyr.fin==1 ? ", "+ plyr.mins + " mins" : "";
              const h_mins = plyr.no_games==0 ? "" : plyr.start==1 || plyr.fin==1 ? ", "+ plyr.mins + " mins" : "";
              const h_bonus = plyr.bonus_proj > 0 ? "+"+plyr.bonus_proj : "";

            return(
              <tr key={plyr.pord} style={{padding:"0"}}  className = {rowColour}>
                <td className={styles.thin_td + h_pts_format2}
                  style={{textAlign:"left"}}
                >
                  {plyr.pl_name}</td>
                  <td><span className={styles.score_min+" font-italic text-secondary"}>{plyr.score+h_mins}</span>
                </td>
                <td className ={"border-right "+styles.thin_td +h_pts_format +h_pts_format2} style={{textAlign:"right"}}>
                  {h_pts2}<span className="font-italic text-secondary">{h_bonus} {h_dgw}</span>{h_sub_emoji}
                </td>
              </tr>
            )})}
            <tr className = "table-success font-weight-bold">
              <td style={{textAlign:"left"}}>Total</td>
              <td></td>
              <td style={{textAlign:"right"}}>{manInfo.tot_pts}<span className="font-italic text-secondary">{" ("+live_pred+")"}</span></td>
            </tr>
            <tr className = "table-success font-weight-bold">
              <td style={{textAlign:"left"}}>Total (inc transfers)</td>
              <td></td>
              <td style={{textAlign:"right"}}>{man_tot}<span className="font-italic text-secondary">{" ("+live_pred2+")"}</span></td>
            </tr>
          </tbody>
        </Table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TeamPop;
//
// TeamPop.propTypes = {
//   show: PropTypes.boolean,
//   handleClose: PropTypes.function,
//   manInfo: PropTypes.array,
//   manTeam:PropTypes.array,
//   manTots:PropTypes.object,
//
// };
