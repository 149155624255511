import React, { useState, useEffect, useCallback } from "react";
import styles from './Leagues.module.scss';

import {Table,Container,Row,Col,Button,Toast,Badge} from "react-bootstrap"
import Form from 'react-bootstrap/Form'
// import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import TeamPop from '../components/TeamPop3'
import XgPop from '../components/XgPop'
import GoogleAd from '../components/GoogleAd'
import SnackAd from '../components/SnackAd'

import loadable from '@loadable/component'
const BootstrapSwitchButton = loadable(() => import('bootstrap-switch-button-react'))


const axios = require("axios");

function LmsLiveTable(props) {
  return (
    <Table
      key={props.leagueInfo.lms_lg_name}
      hover
      size="sm"
      className={styles.leagues_table + " shadow"}
    >
      <thead className="thead-dark">
        <tr>
          <th></th>
          <th style={{textAlign:"left", verticalAlign:"middle"}}>Manager</th>
          <th style={{verticalAlign:"middle"}}>Captain</th>
          <th style={{verticalAlign:"middle"}}>Played<br/>/ Players</th>
          <th style={{verticalAlign:"middle"}}>Trans<br/>Value</th>
          <th style={{verticalAlign:"middle"}}>Wk Pts<br/><span className='font-italic text-secondary'>Proj</span></th>
        </tr>
      </thead>
      <tbody>
        {props.leagueLive.map(manager => {
          // const tcost = manager.trans_cost > 0 ? manager.trans_cost * -1 + " pts" : "";
          const tcost = manager.trans_cost > 0 ? " ("+manager.trans_cost * -1 + "pts)" : "";
          const trans = manager.trans >0 ? manager.trans : "";
          const wk_pts = props.leagueInfo.ord ===1 ? manager.wk_pts_live2 : manager.wk_pts_live_b2;
          // const chip = manager.chip === "wildcard" ? "WC" : manager.chip === "freehit" ? "FH" : manager.chip === "3xc" ? "TC" : manager.chip === "bboost" ? "BB" : "";
          const chip2 = manager.chip === "wildcard" ? "Wildcard" : manager.chip === "freehit" ? "Free Hit" : manager.chip === "3xc" ? "Triple Cap" : manager.chip === "bboost" ? "Bench Boost" : "";
          const val_a = manager.value / 10;
          const val = val_a.toFixed(1);
          const gw_lms = props.leagueInfo.moolah_gw
          // const moolah_red = props.leagueInfo.moolah_red
          // const moolah_lg = props.leagueInfo.lg
          const wk_proj = props.leagueInfo.proj_show ===1 ? "(" + (manager.live_proj_wk*1 + manager.trans_cost * -1) +")": null;
          const lms_status = manager.eliminated2 <100 ? "elim"
          : manager.red === 1 ? "red"
          : "in";
          const elim = gw_lms-1;
          const elim2 = manager.eliminated2 <100 ? manager.eliminated2 : elim;

          // const gw_lms_elim = 40 - gw_lms
          // const gw_lms_elim2 = 41 - gw_lms - moolah_red
          // const gw_lms_elim_m1 = 34 - ((gw_lms-17)*2);
          // const gw_lms_elim_m1 = props.leagueInfo.managers - gw_lms + 1 - props.leagueInfo.elim_fudge;
          // // const gw_lms_elim_m1 = props.leagueInfo.managers - gw_lms + 1 - props.elim_fudge;
          // const gw_lms_elim = gw_lms_elim_m1 +1;
          // const gw_lms_elim_m2 = gw_lms_elim_m1-1;
          // const dbl_elim =props.leagueInfo.elim_nums===2? "last_place" : "in";
           // (props.leagueInfo.elim_nums-1)
          // const gw_lms_elim = gw_lms_elim_m1+1;
          // const gw_lms_elim_m1 = 51 - gw_lms;
          // const gw_lms_elim = 52 - gw_lms;
          // const gw_lms_elim2 = moolah_lg == 1 ? 41 - gw_lms - moolah_red : 42 - gw_lms - moolah_red;
          // const lms_status = manager.eliminated <100 ? "elim"
          // : manager.rank2 > gw_lms_elim_m1 ? "elim"
          // : manager.rank2 > gw_lms_elim_m2 && props.leagueInfo.elim_nums===2 && props.leagueInfo.moolah_red ===0? "elim"
          // : manager.rank2 === gw_lms_elim_m1 ? "last_place"
          // : manager.rank2 === gw_lms_elim_m2 ? dbl_elim
          // : "in";
          // const elim = manager.rank2 === gw_lms_elim_m1 + 2 ? 50 - manager.rank2 : 51 - manager.rank2;
          // const elim = gw_lms-1;
          // const elim = 51 - manager.rank2;

          // const wk_proj = props.leagueInfo.proj_show ===1 ? "(" + (manager.live_proj_wk*1 + manager.trans_cost * -1) +")": null;
          // // const elim = manager.rank2 == 40 ? 1 :40 - manager.rank2;
          // const elim2 = manager.eliminated <100 ? manager.eliminated : elim;
    //elim>6 ? elim +1: elim;
    //       if(ii>gw_lms_elim){
    // $('#lgs2new').append("<tr class='table-dark no-hover'><td class ='no-hover'>"+league_live[i].rank2+"<td class ='no-hover'>"+league_live[i].manager+"<br>"+league_live[i].team_name+"</td><td colspan = '5' style='text-align:center' class ='no-hover'>Elimininated in Gameweek "+elim+"</td></tr>");
    //       if()

          function testline(x) {
            if (x==0) {
              return null;
            }

            return (
              <br/>
            );
          }

          if(lms_status==="elim"){
            return (
              <tr key={manager.indx} className={"table-dark no-hover"}>
                <td>{manager.rank2}</td>
                <td style={{textAlign:"left"}} >
                  {manager.manager}
                  <br/>
                  <span className='font-italic'>
                  {manager.team_name}
                  </span>
                </td>
                <td colSpan = '4' style={{textAlign:"center"}}>{"Elimininated in Gameweek "+elim2}</td>
              </tr>
                )
              } else {
          return (
          <tr key={manager.indx} onClick={()=>props.handleShow(manager.man_code)} style={{cursor:"pointer", padding:"0"}}
            className={
              lms_status === "red" ? "table-danger": ""
            }>
            <td>{manager.rank2}</td>
            <td style={{textAlign:"left",textOverflow:"ellipsis",overflow:"hidden",maxWidth:"100px"}} >
              {manager.manager}
              <br/>
              <span className='font-italic text-secondary'>
              {manager.team_name}
              </span>
              <br/>
              <Badge pill variant="danger">
              {chip2}
              </Badge>
            </td>
            <td style={{textOverflow:"ellipsis",overflow:"hidden",maxWidth:"100px"}} >{manager.cap}</td>
            <td>{manager.s_players +"/"+manager.t_players}</td>
            <td>{trans + tcost}{testline(trans)}{"£"+val}</td>
            <td>{wk_pts}<br/><span className='font-italic text-secondary'>
            {wk_proj}
            </span></td>
          </tr>
        )}

      })}

      </tbody>
    </Table>
  );
}

function LeagueInfo(props) {
  return(
    <Container fluid className ={styles.gwInfo}>
      <Row>
        <Col>
          <h1 className ={styles.gameweekh1}>
           {props.lgName}
          </h1>
        </Col>
      </Row>
    </Container>
  )
}

// function IC(props) {
//   if(props.leagueInfo.lg == 1){
//   return <Button
//       className ={styles.gameweekh1}
//       variant="dark"
//       href="../inner-circle/">
//           Inner Circle Menu
//           </Button>;
// } else {
// return null;
// }
// }

function IC(props) {
  if(props.leagueInfo.lg == 1){
    return (<Row>
      <Col style={{marginTop:"-8px",marginBottom:"5px"}}>
      <Button
      variant="dark"
      block
      href="../inner-circle/">
          Inner Circle Menu
          </Button>
      </Col>
    </Row>);
  } else {
  return null;
  }
  }


class TestSwitch extends React.Component {
// function TestSwitch(props) {
  constructor(props) {
      super(props);
      this.checkState = false;
      this.changecheckState = this.changecheckState.bind(this);
  }

  // const changecheckState = () => {
    changecheckState(){
    if(this.checkState===true)
      {this.neword = 1;
      this.checkState=false;}
      else
      {this.neword = 2;
      this.checkState=true;}
    this.props.setUrl(this.props.newUrl(this.props.lgCode,this.neword))
    // this.props.setUrl(this.props.newUrl(this.props.leagueDetails.pid,this.props.leagueDetails.lg_code,this.neword,this.props.leagueDetails.pg))
    console.log(this.checkState)
  }
  render() {
    return  (
        <Col>
        <div className={styles.inputForm2}>
          <BootstrapSwitchButton
              onstyle="warning" style="border"
              checked = {this.checkState}
              onlabel = "Bonus Pts On"
              offlabel = "Bonus Pts Off"
              onChange={() => this.changecheckState()}
              width={150}

            />
        </div>
        </Col>
      );
}
}

// function TestSwitch(props) {
//
//   const checkState = props.leagueInfo.ord === 2 ? true : false;
//   let neword = 1;
//
//   const changecheckState = () => {
//     if(checkState===true)
//       {
//         // checkState=false;
//         neword = 1;
//       }
//       else
//       {
//         // checkState=true;
//         neword = 2;
//       }
//     props.setUrl(props.newUrl(props.lgCode,neword))
//     console.log(checkState)
//   }
//
//   return  (
//     <Col>
//     <div className={styles.inputForm2}>
//       <BootstrapSwitchButton
//           onstyle="warning" style="border"
//           checked = {this.checkState}
//           onlabel = "Bonus Pts On"
//           offlabel = "Bonus Pts Off"
//           onChange={() => this.changecheckState()}
//           width={150}
//
//         />
//     </div>
//     </Col>
//   );
// }

var ord_get = 1;
var gwPage = 0;

const useLeaguesState = (lgCode) => {
  // const [gw, setGW] = useState(0);
  const league_url = "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/moolah/"+lgCode+"/"+ord_get;
  const [url, setUrl] = useState(league_url)
  const [responseData, setResponseData] = useState([]);

  //PLAYER POP UP STATE
  const [show, setShow] = useState(false);
  const [manData, setManData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = async (newManCode) => {

    const manCode_url = "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/manager/"+newManCode
    console.log('fetch_url:'+manCode_url)
    const response = await axios.get(manCode_url);
    setManData(response.data);
    console.log("fetchran_man");
    setShow(true);
    };

    var manInfo = [];
    var manTeam  = [];
    var manTots = {};

    if (Object.keys(manData).length > 0) {
      manInfo = manData.info;
      manTeam = manData.manager_team;
      manTots = manData.manager_tots;
    }
//END

  const newUrl = (lg_x,ord_x) => {
      return "https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/moolah/"+lg_x+"/"+ord_x;
}

  var leagueLive = [];
  var leagueInfo = [];

  if (Object.keys(responseData).length > 0) {
    leagueLive = responseData.league_live;
    leagueInfo = responseData.info;
    gwPage = leagueInfo.cur_gw;
  }

  const fetchFpl = async (url) => {
    console.log('fetch_url:'+url)
    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");
    // setGW(response.data.status.gw);
  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 60000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

return {leagueInfo, setUrl, newUrl, leagueLive,show,handleShow,handleClose,manInfo,manTeam,gwPage,manTots};
}

const Lms = ({lgCode, lgName}) => {
  // console.log(lgCode);
  const {
    leagueInfo,
    setUrl,
    newUrl,
    leagueLive,
    show,
    handleShow,
    handleClose,
    manInfo,
    manTeam,
    gwPage,
    manTots,
  } = useLeaguesState(lgCode);

  return (
    <Container fluid >
      <Row>
        <Col xs={{span:12, order:2}} lg={{span:3, order:1}} style={{ marginLeft: 0 }}>
        <SnackAd ad_type = "skyscraper" ad_num = {1}/>
        </Col>
        <Col xs={{span:12, order:1}} lg={{span:6, order:2}} className={styles.App2}>
          <IC leagueInfo= {leagueInfo}/>
          <LeagueInfo lgName = {lgName}/>
          <TeamPop show= {show} handleClose = {handleClose} manInfo ={manInfo} manTeam={manTeam} manTots={manTots}/>
          <Container fluid className ={styles.gwInfo}>
            <Row>
              <TestSwitch leagueInfo= {leagueInfo} setUrl={setUrl} newUrl = {newUrl} lgCode = {lgCode} gwPage={gwPage}/>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <LmsLiveTable leagueInfo= {leagueInfo} leagueLive = {leagueLive} handleShow = {handleShow}/>
            </Row>
          </Container>
          </Col>
          <Col xs={{span:12, order:3}} lg={{span:3, order:3}} style={{ marginRight: 0 }}>
          <SnackAd ad_type = "skyscraper" ad_num = {2}/>
          </Col>
        </Row>
      </Container>
  );
}

// <TestSwitch leagueInfo= {leagueInfo} setUrl={setUrl} newUrl = {newUrl} lgCode = {lgCode} gwPage={gwPage}/>
// <IC leagueInfo= {leagueInfo}/>

export default Lms;
